
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "../../store/enums/StoreEnums";
  import { getIllustrationsPath } from "../../core/helpers/assets";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import { reactive } from "vue";
  import { ref } from "vue";
  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { getLatipayChannelsRequest } from "@/latipay/apis/services/LatipayChannelsService";
  import { DateTimeUtils } from "@/latipay/utils/DateTimeUtils";

  export default defineComponent({
    name: "channels",
    components: {},
    setup() {
      const store = useStore();


      const channelName = ref<string | null>(null);
      const gatewayName = ref<string | null>(null);

      const pageNumber = ref(1);
      const pageSize = ref(20);
      const sort = ref<string | null>(null);

      const loading = ref(false);

      const formInline = reactive({
        channelName: "",
        gatewayName: ""
      });


      const {
        latipayChannelsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getLatipayChannels
      } = getLatipayChannelsRequest();


      const getData = async () => {
        loading.value = true;

        // await updateQueries();
        await getLatipayChannels(
          formInline.gatewayName,
          formInline.channelName,
          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loading.value = false;

        console.log(latipayChannelsRef);
      };


      onMounted(async () => {
        // setCurrentPageTitle("Users");
        await getData();
      });

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        pageSize.value = val;
        await getData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        pageNumber.value = val;
        await getData();
      };

      onMounted(() => {


        setCurrentPageTitle("Channels");
      });


      return {
        getIllustrationsPath,
        formInline,
        channelName,
        gatewayName,
        getData,
        loading,
        updatePageSize,
        updatePageNumber,
        latipayChannelsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        DateTimeUtils
      };
    },
    methods: {
      async resetFormFields() {

        (this.$refs["searchForm"] as any).resetFields();
        await this.getData();

      },
      async onSubmitSearchForm() {
        await this.getData();
      },
      priceFormat(value) {
        return "$ " + value;
      }

    },
    data() {
      return {
        columns: [
          {
            label: "Product",
            field: "product"
          },
          {
            label: "Price",
            field: "price",
            dataFormat: this.priceFormat
          },
          {
            label: "Quantity",
            field: "quantity"
          },
          {
            label: "Country",
            field: "address.country"
          },
          {
            label: "City",
            field: "address.city"
          }
        ],
        data: [
          {
            product: "Beverage",
            price: 10,
            quantity: 2,
            address: {
              country: "Armenia",
              city: "Yerevan"
            }
          },
          {
            product: "Snack",
            price: 12,
            quantity: 6,
            address: {
              country: "Armenia",
              city: "Yerevan"
            }
          },
          {
            product: "Beverage",
            price: 10,
            quantity: 5,
            address: {
              country: "Armenia",
              city: "Yerevan"
            }
          },
          {
            product: "Snack",
            price: 12,
            quantity: 3,
            address: {
              country: "Armenia",
              city: "Yerevan"
            }
          }
        ]
      };
    }
  });
